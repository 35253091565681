@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .textfield {

    
        @apply md:w-[21.8rem] w-[15rem] h-[4rem] px-2   text-lg bg-bodyColor  border-2
        border-white rounded-lg
      }

 }
